// https://splidejs.com/guides/options/

const options = {
  rewind: false,
  gap   : '1rem',
  focus    : 'center',
  trimSpace: false,
  perPage: 3,
  autoWidth: true,
  arrows: false,
  pagination: false,
  updateOnMove: true,
  keyboard: true,
  perMove: 1,
  noDrag: '.pm_cover__image, .img-vinyl, .rad-slider',
  classes: {
    arrows: 'splide__arrows',
    arrow : 'splide__arrow',
    prev  : 'splide__arrow--prev',
    next  : 'splide__arrow--next',
  },
  breakpoints: {
    460: {
      gap   : '0',
    },
    780: {
      gap   : '0.5rem',
    },
  }
};

export default options;
