import axios from "axios";

//const baseURL = "https://api.github.com";
const baseURL = "https://audius-metadata-1.figment.io";

const http = axios.create({
    baseURL,
    timeout: 20000,
    headers: {
        "Content-type": "application/json",
    },
});

// Request interceptor
http.interceptors.request.use(
    (config) => {
        config.params = {
            ...config.params,
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

// Response interceptor
http.interceptors.response.use(
    async (response) => {
        const res = response;
        const { status } = res;

        // Custom status code validation
        if (status !== 200) {
            return Promise.reject({
                response,
                message: "CustomError",
            });
        }

        return res.data.data;
    },
    // export default catch
    (error) => {
        if (error.response && error.response.data) {
            console.error(`[Axios Error]`, error.response);
        }
    },
);

export default http;
