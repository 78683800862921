<template>
  <section class="pm_cover">
    <figure class="pm_cover__image" :class="isPlaying ? 'is-playing' : 'is-stopped'" :data-cover="cover">
      <player-vinyl-svg
          :cover="cover"
          @rotate="rotate"
          :play="isPlaying"
      ></player-vinyl-svg>
      <img
          @click.prevent="emitTogglePlayPause()"
          alt="Vue logo"
          class="img-cover"
          :src="cover"
      />
    </figure>
  </section>
</template>

<script>
import { onMounted } from 'vue'
import playerVinylSvg from "../../components/player/player-vinyl-svg"

export default {
  name: 'PlayerCover',
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    cover: {
      type: String,
      default: null
    }
  },
  components: { playerVinylSvg },
  setup(props, { emit }) {
    /**
     * Method ------
     */
    function rotate(name, degree) {
      document.documentElement.style.setProperty('--' + name, degree)
    }

    function emitTogglePlayPause() {
      emit('emit-togglePlayPause', true)
    }

    onMounted( () => {});

    return {
      /* Method ------ */
      rotate,
      emitTogglePlayPause
    }
  }
}
</script>

<style lang="scss">
  .pm_cover {
    width: 100%;
    height: auto;
    // background-color: var(--color-light);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //overflow: hidden;
    transition: transform 400ms;
    transform: scale(.95);
    opacity: 0.55;
    transform-origin: center center;
    padding: 10px 35px;
    //background: var(--color-transparent);
    @media (min-width: 460px) {
      padding: 10px 40px;
    }
    @media (min-width: 780px) {
      padding: 20px 50px;
    }
    &__image {
      z-index: 1;
      position: relative;
      display: block;
      height: 180px;
      width: 180px;
      margin: 0 auto;
      transition: width 0.2s cubic-bezier(1, 0, 0.255, 1), height 0.2s cubic-bezier(1, 0, 0.255, 1);
      border-radius: var(--border-radius);
      //-webkit-box-shadow: 0px 0px 10px 5px #0000004d;
      background: var(--color-transparent);
      @media (min-width: 460px) {
        height: 220px;
        width: 220px;
      }
      @media (min-width: 780px) {
        height: 260px;
        width: 260px;
      }
      &:hover{
        .rad-slider{
          left: 20%;
        }
      }
      &:before {
      background-image: attr(data-cover url);
      }
      &.is-playing{
        .img-cover{
          left: -17%;
        }
        .rad-slider{
          left: 23%;
        }
        /*&:hover{
          .img-vinyl{
            left: 30%;
          }
        }*/
      }
    }
    .img-cover{
      background: var(--background);
      //border: 4px solid var(--background);
      border-radius: var(--border-radius);
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      transition: left 0.3s cubic-bezier(1, 0, 0.255, 1);
      cursor: pointer;
    }
  }
  .is-active{
    > .pm_cover {
      transform: scale(1);
      opacity: 1;
    }
  }
  .is-playing{
    .img-cover{
      box-shadow: 4px 0px 3px 0px #0000004d;
    }
  }
</style>
