<template>
  <div class="pm_player_wrapper">

    <div class="loading" v-if="!currentPlaylist.length">
      <unicon name="robot"></unicon>
      <div class="bubble-info">
        <div class="bubble">
          <p>Loading 🎵 🎶. <br/><small>Init Audius api, please wait a second..</small></p>
        </div>
        <div class="bubble medium"></div>
        <div class="bubble small"></div>
      </div>
    </div>

    <Splide v-if="currentPlaylist.length > 0" :options="splideOptions" @splide:dragged="onDragEnd" ref="splide" has-slider-wrapper>
      <SplideSlide v-for="(song, key) in currentPlaylist" :key="song.id">
        <player-cover
            :cover="song.artwork['480x480']"
            :isPlaying="(key === currentSongIndex) ? isPlaying : false"
            @emit-togglePlayPause="togglePlayPause()"
        ></player-cover>
      </SplideSlide>
    </Splide>

    <player-timer
        v-if="currentPlaylist.length > 0"
        :song="currentPlaylist[currentSongIndex]"
        :timer="currentTimer"
        :duration="durationTimer"
        :progress="progress"
        @emit-seekTimer="seekTimer"
    />

    <div class="pm_controls" v-if="currentPlaylist.length > 0">
      <a href="#" @click.prevent="prevSong()" class="pm_controls__action medium">
        <unicon name="previous"></unicon>
      </a>
      <a href="#" @click.prevent="togglePlayPause()" class="pm_controls__action large">
        <unicon :name="isPlaying !== true ? 'play' : 'pause'"></unicon>
      </a>
      <a href="#" @click.prevent="nextSong()" class="pm_controls__action medium">
        <unicon name="step-forward"></unicon>
      </a>
    </div>

    <player-bottom-sheet :playlists="playlists"/>

  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed, ref, onMounted, watch, reactive, toRefs} from 'vue'

import playerCover from "../components/player/player-cover"
import playerTimer from "../components/player/player-timer"
import playerBottomSheet from "../components/player/player-bottom-sheet"
import songsJson from '../static/songs';
import splideConf from '../static/splide.conf';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import {formatTime} from "../utils/utils";
import {Howl, Howler} from "howler";
import { event } from "vue-gtag";

export default {
  name: 'Home',
  components: { playerCover, playerTimer, playerBottomSheet, Splide, SplideSlide },
  setup () {
    const store = useStore()
    const storePlayer = store.state.player
    // Global
    const isReady = computed(() => storePlayer.isReady)
    const isPlaying = computed(() => storePlayer.isPlaying)
    // Playlist Splide
    const playlists = ref(songsJson)
    //const currentPlaylist = computed(() => storePlayer.currentPlaylist);
    const currentPlaylist = computed(() => store.getters["player/currentPlaylist"]);
    const currentSongIndex = computed(() => storePlayer.currentSongIndex);
    const splide = ref();
    const splideOptions = ref(splideConf);
    // Howler JS
    let howl = null;
    let raf = null;
    const state = reactive({
      currentTimer: '00:00',
      durationTimer: '00:00',
      progress: 0,
      volume: 1
    });


    /**
     * Method ------
     */
    function togglePlayPause() {
      store.dispatch('player/togglePlayPause', !isPlaying.value)
    }

    function nextSong() {
      const payload = { current: currentSongIndex.value, total: playlists.value.length }
      store.dispatch('player/nextSong', payload)
      splide.value.go('+1')
    }

    function prevSong() {
      store.dispatch('player/prevSong', currentSongIndex.value)
      splide.value.go('-1')
    }

    function onDragEnd(splide) {
      store.dispatch('player/changeSongIndex', splide.index)
    }

    function destroyHowler() {
      if (howl) {
        howl.off() // Remove event listener
        howl.stop() // Stop playback
        howl.unload() // Remove sound from pool
        howl = null // Destroy it
      }
    }

    const initAudioPlayer = () => {
      destroyHowler()
      // get tracks via current playlist and current index
      let id = currentPlaylist.value[currentSongIndex.value]?.id;
      let srcUrl = `https://audius-metadata-1.figment.io/v1/tracks/${id}/stream?app_name=playme`;
      // load howler with params
      howl = new Howl({
        //src: [playlists.value[currentSongIndex.value].src],
        src: [srcUrl],
        format: ['mp3'], //https://github.com/goldfire/howler.js#format-array-
        html5: true,
        preload: true,
        autoload: false,
        volume: state.volume,
        fade: 0,
        onload: () => {
          store.dispatch('player/toggleReady', true)
          state.durationTimer = formatTime(howl.duration())
        },
        onplay: () => {
          store.dispatch('player/togglePlayPause', true)
          state.durationTimer = formatTime(howl.duration())
          /*raf = requestAnimationFrame(stepFunction.bind(this))*/
          raf = setInterval(function(){
            stepUpdateTimer()
          },500)
        },
        onstop: () => {
          clearInterval(raf)
        },
        onpause: () => {
          clearInterval(raf)
        },
        onend: () => {
          clearInterval(raf)
          nextSong()
        },
        onseek: () => {
          //seekTimer($event)
          //clearInterval(raf)
        }
      })
      // fix & preset volume howler
      Howler.masterGain.gain.value = state.volume
      // Gtag
      event('player_loaded', { method: 'PlayMe' })
    }

    function stepUpdateTimer() {
      // Update state info
      const seek = howl.seek() || 0
      state.currentTimer = formatTime(Math.round(seek))
      state.progress = ((seek / howl.duration()) * 100)
      // Clear if not playing
      if (!howl.playing()) {
        clearInterval(raf)
      }
    }

    function seekTimer(seekEventAction) {
      // console.log(seekEventAction)
      // Determine our current seek position.
      const per = seekEventAction.clickX / seekEventAction.progressbarWidth
      const seek = howl.duration() * per
      state.currentTimer = formatTime(Math.round(seek))
      state.progress = per * 100
      // Apply time & Play
      howl.seek(seek)
    }

    const initCallApiPlaylistTracks = () => {
      store.dispatch('player/initCurrentPlaylist', 'nlKX6');
    }

    /**
     * Mounted, Watcher ------
     */
    onMounted(() => {
      if ( splide.value && splide.value.splide ) {
        console.log( splide.value.splide.length );
      }
      console.log('onMounted');
      setTimeout(() => {
        initCallApiPlaylistTracks();
      }, 2000);
    });

    watch(
        isPlaying,
        () => {
          if (isPlaying.value) {
            event('player_start', { method: 'PlayMe' })
            howl.play()
          } else {
            clearInterval(raf)
            howl.pause()
          }
        }
    )

    watch(
        currentSongIndex,
        () => {
          // Pause
          howl.stop()
          if (isPlaying.value) {togglePlayPause()}
          setTimeout(()=> {
            // Play
            initAudioPlayer()
            howl.play()
            togglePlayPause()
          },400);
        }
    )

    watch(
        currentPlaylist,
        () => {
          initAudioPlayer();
          console.log(howl)
        }
    )


    return {
      /* Data------ */
      isReady,
      isPlaying,
      playlists,
      currentPlaylist,
      currentSongIndex,
      splide,
      splideOptions,
      ...toRefs(state),
      /* Method ------ */
      togglePlayPause,
      nextSong,
      prevSong,
      onDragEnd,
      seekTimer
    }
  }
}
</script>

<style lang="scss">
  .pm_player_wrapper {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    max-width: 650px;
    max-height: 847px;
    margin: 0 auto;
    color: var(--color);
    justify-content: center;
    position: relative;
    padding: var(--space);
    overflow: hidden;/*
    -webkit-box-shadow: 0px 0px 10px 3px var(--shadow);
    -moz-box-shadow: 0px 0px 10px 3px var(--shadow);
    box-shadow: 0px 0px 10px 3px var(--shadow);*/
  }
  .splide {
    margin-top: -40px;
  }
  .splide__arrow--prev svg {
    transform: scaleX(1);
  }
  .pm_controls{
    //background: var(--color-transparent);
    padding: var(--gap) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &__action {
      display: inline-flex;
      margin: 0 var(--space);
      padding: var(--space);
      justify-content: center;
      align-items: center;
      background: var(--background);
      border-radius: 10px;
      position: relative;
      transition: box-shadow 0.3s ease-in-out;
      .unicon{
        width: var(--sizeicon);
      }
      &.large {
        padding: 15px;
        //border: 2px solid var(--color);
        background: var(--linear-background-reverse);
        .unicon{
          width: var(--sizeicon-large);
          svg{
            fill: var(--color);
          }
        }
      }

      &:hover {
        -webkit-box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.2);
        box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.2);
      }
    }
  }
  .loading{
    overflow: hidden;
    width: 100%;
    padding: 5px 0 4px 0;
    display:flex;
    align-items: flex-end;
    justify-content: center;
    .unicon{
      width: 70px;
      height:auto;
    }
    .bubble-info{
      display:flex;
      flex-direction: column;
      position: relative;
      margin: 0 0 43px -10px;
      .bubble {
        width: 100%;
        height: auto;
        border-radius: 11px;
        background-color: var(--color);
        display: flex;
        margin: 0 0 2px 0;
        padding: var(--space);
        &.medium {
          width: 30%;
          height: 20px;
        }
        &.small {
          width: 15px;
          height: 15px;
          padding: 5px;
        }
        p{
          margin: var(--gap);
          color: var(--color-inversed);
          text-align: left;
          font-weight: 700;
          line-height: 1;
          font-size: 1.1rem;
          white-space: nowrap;
          small{
            font-size: 0.8rem;
            font-weight: 500;
          }
        }
      }
    }
  }
</style>
