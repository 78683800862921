<template>
  <div class="m_wrapper">
    <div id="header">
      <playme-logo/>
      <ul class="nav">
        <li><router-link to="/about"><unicon name="comment-info"></unicon></router-link></li>
        <li>
          <unicon
            @click.stop="toggleTheme(e)"
            :name="userTheme === 'light-theme' ? 'sun' : 'moon'">
          </unicon>
        </li>
      </ul>
    </div>
    <div class="m_wrapper_content">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss">
@import "./assets/scss/style.scss";
</style>

<script>
import PlaymeLogo from "./components/logo/playme-logo";

export default {
  name: 'App',
  components:{
    PlaymeLogo
  },
  mounted() {
    const windowsUserTheme = this.getMediaPreference();
    const localStorageTheme = localStorage.getItem("user-theme");
    if (localStorageTheme) {
      this.setTheme(localStorageTheme);
    } else if (windowsUserTheme) {
      this.setTheme(windowsUserTheme);
    }else {
      this.setTheme(this.userTheme);
    }
  },
  data() {
    return {
      userTheme: "light-theme",
    };
  },
  methods: {
    toggleTheme() {
      const activeTheme = localStorage.getItem("user-theme");
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },

    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      // document.documentElement.className = theme;
      document.body.className = theme;
    },

    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
  },
}
</script>
