<template>
  <router-link to="/" class="logo" tag="a">
    <svg viewBox="0 0 323 259" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="paint0_linear_39_154" x1="24.7447" y1="237.351" x2="126.518" y2="59.8614" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9C55D4"/>
          <stop offset="1" stop-color="#4ED2D2"/>
        </linearGradient>
        <linearGradient id="paint1_linear_39_154" x1="236" y1="183.5" x2="291.264" y2="245.695" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9C55D4"/>
          <stop offset="1" stop-color="#4ED2D2"/>
        </linearGradient>
      </defs>
      <g id="logo-playme">
        <path id="p-letter" d="M225.565 71.041C274.733 94.6379 274.733 164.646 225.565 188.242L93.1238 251.804C49.9747 272.512 -2.51678e-06 241.064 0 193.203L6.68478e-06 66.0801C9.20156e-06 18.2191 49.9747 -13.2289 93.1238 7.47935L225.565 71.041Z" fill="url(#paint0_linear_39_154)"/>
        <path id="m-letter" d="M241.732 33.6516L223.696 42.4449L223.639 42.4174L108.557 98.6141C97.5671 103.836 88.4832 100.917 84.87 93.5148C80.1944 83.9365 86.2066 76.4037 95.4712 71.8171L189.363 25.9678L189.198 25.8885L228.585 6.68576C271.763 -14.3649 322.07 17.0761 322.07 65.1118L322.07 193.891C322.07 241.927 271.763 273.368 228.585 252.317L189.552 233.287L206.801 225.008L224.05 216.73L241.732 225.351C264.982 236.686 292.07 219.756 292.07 193.891L292.07 65.1118C292.07 39.2465 264.982 22.3166 241.732 33.6516Z" fill="url(#paint1_linear_39_154)"/>
      </g>
    </svg>
    <h1> Play<span>Me</span> <small>BETA 🚧</small></h1>
  </router-link>
</template>

<script>
export default {
  name: 'PlaymeLogo',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo {
  font-style: normal;
  font-size: 32px;
  line-height: 1.2;
  margin: 0px 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  svg{
    width: 30px;
    margin-right: var(--space);
  }
  h1 {
    font-family: Quicksand-Bold, sans-serif;
    font-size: 26px;
    line-height: 1;
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--color);
    margin-top: -3px;
    span{
      font-family: Quicksand-Bold, sans-serif;
      transition: margin-left 0.2s;
      color: var(--contrast);
    }
    small{
      font-family: Quicksand-Light, sans-serif;
      font-size: 7px;
      transition: margin-left 0.2s;
      background: var(--color-transparent);
      padding: 3px;
      margin-left: -20px;
      margin-bottom: -45px;
    }
  }
  &:hover{
    color:inherit;
    h1{
      span{
        margin-left: 5px;
      }
    }
  }
}
</style>
