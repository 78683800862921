<template>
  <div class="privacy container">

    <h3>
      <unicon name="shield-check"></unicon> Privacy
    </h3>

    <p>
      <unicon name="no-entry"></unicon> Work in progress 🚧..
    </p>

    <p>
      &lt;&sol;💻&gt; with ❤ by
      <a href="https://in2.app" title="In2 App" target="_blank" rel="noopener">@in2.app</a>.
      Have Fun 🎶
    </p>

  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>

<style scoped lang="scss">
  .privacy{
    text-align: left;
  }
</style>
