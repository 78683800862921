import http from "../http-common";

class ApiAudiusService {
    getAllTracksByPlaylistId(playlist_id) {
        return http.get(`/v1/playlists/${playlist_id}/tracks?app_name=playme`);
    }
    getOneTrack(track_id) {
        return http.get(`/tracks/${track_id}?app_name=playme`);
    }
    getTrackStream(track_id) {
        return http.get(`/tracks/${track_id}/stream?app_name=playme`);
    }
}


export default new ApiAudiusService();
