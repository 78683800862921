<template>
  <div
      class="rad-slider"
      :name="name"
      :class="{moving: moving, dirty: dirty, play: play}"
      @mousedown="start"
      @mousemove="move"
      @mouseup="stop"
      @touchstart="start"
      @touchmove="move"
      @touchend="stop"
  >
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" class="img-vinyl">
    <title>Full Vinyls</title>
    <defs>
      <linearGradient id="gradientCircle" gradientTransform="rotate(90)">
        <stop offset="10%"  stop-color="#141415" />
        <stop offset="50%" stop-color="#686868" />
        <stop offset="90%"  stop-color="#141415" />
      </linearGradient>
    </defs>
    <path fill="#181819" d="M400,1C179.6,1,1,179.6,1,400s178.6,399,399,399s399-178.6,399-399S620.4,1,400,1zM400,409.8c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8S405.4,409.8,400,409.8z" />
    <g stroke-width="1.5" stroke="url('#gradientCircle')" fill="#181819">
      <path d="M400,20C190.1,20,20,190.1,20,400s170.1,380,380,380s380-170.1,380-380S609.9,20,400,20z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,30C195.7,30,30,195.6,30,400s165.7,370,370,370s370-165.7,370-370S604.3,30,400,30z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,40C201.2,40,40,201.2,40,400s161.2,360,360,360s360-161.2,360-360S598.8,40,400,40z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,50C206.7,50,50,206.7,50,400s156.7,350,350,350s350-156.7,350-350S593.3,50,400,50z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,60C212.2,60,60,212.2,60,400s152.2,340,340,340s340-152.2,340-340S587.8,60,400,60z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,70C217.8,70,70,217.8,70,400s147.8,330,330,330s330-147.8,330-330S582.2,70,400,70z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,80C223.3,80,80,223.3,80,400s143.3,320,320,320s320-143.3,320-320S576.7,80,400,80z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,90C228.8,90,90,228.8,90,400s138.8,310,310,310s310-138.8,310-310S571.2,90,400,90z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,100c-165.7,0-300,134.3-300,300s134.3,300,300,300s300-134.3,300-300S565.7,100,400,100z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,110c-160.2,0-290,129.8-290,290s129.8,290,290,290s290-129.8,290-290S560.2,110,400,110z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,120c-154.6,0-280,125.4-280,280s125.4,280,280,280s280-125.4,280-280S554.6,120,400,120z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,130c-149.1,0-270,120.9-270,270s120.9,270,270,270s270-120.9,270-270S549.1,130,400,130z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,140c-143.6,0-260,116.4-260,260s116.4,260,260,260s260-116.4,260-260S543.6,140,400,140z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,150c-138.1,0-250,111.9-250,250s111.9,250,250,250s250-111.9,250-250S538.1,150,400,150z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,160c-132.5,0-240,107.5-240,240s107.5,240,240,240s240-107.5,240-240S532.5,160,400,160z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,170c-127,0-230,103-230,230s103,230,230,230s230-103,230-230S527,170,400,170z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,180c-121.5,0-220,98.5-220,220s98.5,220,220,220s220-98.5,220-220S521.5,180,400,180z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,190c-116,0-210,94-210,210s94,210,210,210s210-94,210-210S516,190,400,190z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
      <path d="M400,200c-110.5,0-200,89.5-200,200s89.5,200,200,200s200-89.5,200-200S510.5,200,400,200z M399.2,414.8c-8.6,0-15.5-6.9-15.5-15.5s6.9-15.5,15.5-15.5s15.5,6.9,15.5,15.5S407.8,414.8,399.2,414.8z"/>
    </g>
    <path id="cover" fill="#4bb749" opacity="0" d="m400,226.72501c-95.62166,0 -173.27499,77.52768 -173.27499,173.27499s77.65333,173.27499 173.27499,173.27499s173.27499,-77.65333 173.27499,-173.27499s-77.65333,-173.27499 -173.27499,-173.27499zm0,187.97634c-8.04177,0 -14.70136,-6.53394 -14.70136,-14.70136s6.53394,-14.70136 14.70136,-14.70136s14.70136,6.53394 14.70136,14.70136s-6.65959,14.70136 -14.70136,14.70136z" />
    <defs>
      <clipPath id="coverClip">
        <path fill="#4bb749" d="m400,226.72501c-95.62166,0 -173.27499,77.52768 -173.27499,173.27499s77.65333,173.27499 173.27499,173.27499s173.27499,-77.65333 173.27499,-173.27499s-77.65333,-173.27499 -173.27499,-173.27499zm0,187.97634c-8.04177,0 -14.70136,-6.53394 -14.70136,-14.70136s6.53394,-14.70136 14.70136,-14.70136s14.70136,6.53394 14.70136,14.70136s-6.65959,14.70136 -14.70136,14.70136z" />
      </clipPath>
    </defs>
    <image :xlink:href="cover" x="200" y="200" height="500" width="500" clip-path="url(#coverClip)"/>
  </svg>
    <div class="label">{{degree}}&deg;</div>
  </div>
</template>

<script>
export default {
  name: 'PlayerVinylSvg',
  props: {
    cover: String,
    play: {
      type: Boolean,
      default: false
    },
    degreeParent: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      name: 'a',
      degree: 0,
      minDegree: 0,
      maxDegree: 360,
      moving: false,
      dirty: false
    }
  },
  mounted() {
    this.processDegree(this.degree)
    if (this.play) {
      this.autoplay(this.degree)
    }
  },
  watch:{
    play: function() {
      this.autoplay(this.degree)
    },
  },
  beforeUpdate() {
    this.processDegree(this.degree)
  },
  methods: {
    keepValueInRange(value, min, max) {
      let newValue = (value >= max ? value - max : (value < min ? value + max : value))
      return newValue
    },
    processDegree(value){
      this.degree = this.keepValueInRange(value, this.minDegree, this.maxDegree)
      this.$el.style.setProperty('--degree', this.degree + 'deg')
      this.$emit('rotate', this.name, this.degree + 'deg')
    },
    getPointerDeg(e){
      let x = e.touches && e.touches.length ? e.touches[0].clientX : e.clientX
      let y = e.touches && e.touches.length ? e.touches[0].clientY : e.clientY
      return Math.floor(Math.atan2(x - (this.metrics.left + this.metrics.width/2), y - (this.metrics.top + this.metrics.height/2))*(-180/Math.PI)) + 180
    },
    autoplay() {

      const interval = setInterval(() => {
        if(!this.play){
            clearInterval(interval);
            return;
        }
        //console.log(this.degree)
        this.degree++
        if (this.degree === 360) {
          this.degree = 0
          //clearInterval(interval);
        }
      }, 50);

    },
    start(e) {
      this.moving = true
      this.dirty = true
      this.metrics = e.target.getBoundingClientRect()
      this.pointer = this.getPointerDeg(e) - this.degree
    },
    move(e) {
      if(this.moving) {
        let step = 1
        let input = this.getPointerDeg(e)
        // console.log(input)
        if(e.shiftKey && e.metaKey) {
          step = 45
        } else if(e.shiftKey) {
          step = 15
        } else if(e.metaKey) {
          step = 30
        }
        this.degree = Math.ceil((input - this.pointer) / step) * step
        this.processDegree(this.degree)
      }
    },
    stop() {
      this.moving = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
:root {
  --a: 0deg;
  --color1: #d41459;
  --color2: #f26191;
}

// add these as needed
.rotate-a {transform: rotate(var(--a));}
.rotate-a--reverse {transform: rotate(calc(0deg - var(--a)));}

// slider
.rad-slider {
  --degree: 0deg;
  --degree-reverse: calc(0deg - var(--degree));
  position: absolute;
  left: 2.5%;
  top: 2.5%;
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 45vmin;
  height: 45vmin;*/
  color: #fff;
  //border: .1em solid;
  border-radius: 50%;
  //transition: box-shadow 300ms linear, left 0.3s;
  transform: rotate(var(--degree));
  transition: left 0.3s;
  cursor: grab;
  -webkit-box-shadow: 0px 0px 5px 3px #0000004d;
  box-shadow: 0px 0px 5px 3px #0000004d;
  .label {
    user-select: none;
    pointer-events: none;
    transform: rotate(var(--degree-reverse));
  }
  &::before {
    content: '';
    position: absolute;
    top: 5%;
    bottom: 70%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: .1em;
    max-height: 3em;
    border-radius: 1em;
    background: rgba(255,255,255,0.1);
    transition: background 800ms linear;
  }
  &:hover {
    cursor: grab;
    box-shadow: 0 0 3em -1em var(--color1);
  }
  &.moving {
    cursor: grabbing;
    box-shadow: 0 0 3em -.5em var(--color1);
  }
}

// some style opinions
.rad-slider {
  &:not(.dirty) .label {
    opacity: 1;
  }
  .label {
    position: absolute;
    left: 50%;
    top: 5%;
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 800ms linear;
    opacity: .1;
    font-size: 0.7825rem;
  }
  &:hover .label {
    opacity: 0.4;
  }
  &:hover {
    &::before{
      background: rgba(255,255,255,0.4);
    }
  }
}

</style>
