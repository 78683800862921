import { createApp } from 'vue'
import App from './App.vue'

import './registerServiceWorker'
import router from './router'
import store from './store'

import VueGtag from "vue-gtag";

import Unicon from 'vue-unicons'
import { uniBrightness, uniMoon, uniHeadphones, uniHeadphoneSlash, uniMusicNote, uniMusicTuneSlash, uniPauseCircle, uniPlay, uniPlayCircle, uniPause, uniMountains, uniPrevious, uniSkipForward, uniSkipForwardAlt, uniStepBackwardAlt, uniStepBackward, uniStepForward, uniStopCircle, uniBackward, uniForward, uniEqualCircle, uniClock, uniUser, uniTrafficBarrier, uniNoEntry, uniPaintTool, uniWheelBarrow, uniConstructor, uniIcons, uniPathfinder, uniPalette, uniMusic, uniAirplay, uniCompactDisc, uniLockAlt, uniLockOpenAlt, uniShieldCheck, uniQrcodeScan, uniShoppingBasket, uniShoppingCart, uniHeartSign, uniBrightnessLow, uniFavorite, uniHome, uniLayerGroup, uniLink, uniRocket, uniEnvelope, uniEnvelopeAdd, uniBug, uniBluetoothB, uniClosedCaptioning, uniAndroidMonochrome, uniDiscordMonochrome, uniGoogleMonochrome, uniInstagramMonochrome, uniPaypalMonochrome, uniRedditAlienAltMonochrome, uniSnapchatGhostMonochrome, uniTwitterMonochrome, uniCloudWifi, uniSync, uniSyncSlash, uniVolume, uniVolumeDown, uniSetting, uniFacebookF, uniCommentInfo, uniPicture, uniCircle, uniHeart, uniSquare, uniTriangle, uniFire, uniMessage, uniSun, uniVolumeMute, uniSunset, uniMoonset, uniMountainsSun, uniRobot, uniRepeat, uniLayerGroupSlash, uniEllipsisV, uniLayers, uniLayersSlash, uniShareAlt, uniAngleUp, uniAngleDown, uniArrowRandom, uniListUl, uniApps, uniBars, uniStopwatch, uniStopwatchSlash, uniGithubAlt, uniBrain, uniAndroidAlt, uniAppleAlt, uniApple } from 'vue-unicons/dist/icons'

Unicon.add([uniBrightness, uniMoon, uniHeadphones, uniHeadphoneSlash, uniMusicNote, uniMusicTuneSlash, uniPauseCircle, uniPlay, uniPlayCircle, uniPause, uniMountains, uniPrevious, uniSkipForward, uniSkipForwardAlt, uniStepBackwardAlt, uniStepBackward, uniStepForward, uniStopCircle, uniBackward, uniForward, uniEqualCircle, uniClock, uniUser, uniTrafficBarrier, uniNoEntry, uniPaintTool, uniWheelBarrow, uniConstructor, uniIcons, uniPathfinder, uniPalette, uniMusic, uniAirplay, uniCompactDisc, uniLockAlt, uniLockOpenAlt, uniShieldCheck, uniQrcodeScan, uniShoppingBasket, uniShoppingCart, uniHeartSign, uniBrightnessLow, uniFavorite, uniHome, uniLayerGroup, uniLink, uniRocket, uniEnvelope, uniEnvelopeAdd, uniBug, uniBluetoothB, uniClosedCaptioning, uniAndroidMonochrome, uniDiscordMonochrome, uniGoogleMonochrome, uniInstagramMonochrome, uniPaypalMonochrome, uniRedditAlienAltMonochrome, uniSnapchatGhostMonochrome, uniTwitterMonochrome, uniCloudWifi, uniSync, uniSyncSlash, uniVolume, uniVolumeDown, uniSetting, uniFacebookF, uniCommentInfo, uniPicture, uniCircle, uniHeart, uniSquare, uniTriangle, uniFire, uniMessage, uniSun, uniVolumeMute, uniSunset, uniMoonset, uniMountainsSun, uniRobot, uniRepeat, uniLayerGroupSlash, uniEllipsisV, uniLayers, uniLayersSlash, uniShareAlt, uniAngleUp, uniAngleDown, uniArrowRandom, uniListUl, uniApps, uniBars, uniStopwatch, uniStopwatchSlash, uniGithubAlt, uniBrain, uniAndroidAlt, uniAppleAlt, uniApple])


const app = createApp(App)

app
    .use(router)
    .use(store)
    .use(Unicon)
    .use(VueGtag, {
        config: {
            id: "G-NFS33EFT3G",
            params: {
                anonymize_ip: true
            }
        }
    }, router)
    .mount('#app')
