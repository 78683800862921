<template>
  <section class="pm_timer">

    <transition appear name="fade">
      <div class="author__link" v-show="showSocial">
        <a href="#" v-if="song.name"><unicon name="link"></unicon></a>
        <a href="#"><unicon name="twitter" icon-style="monochrome"></unicon></a>
        <a href="#"><unicon name="cloud-wifi"></unicon></a>
        <a href="#"><unicon name="instagram" icon-style="monochrome"></unicon></a>
        <a href="#"><unicon name="facebook-f"></unicon></a>
      </div>
    </transition>

    <h3 class="author">
      <a href="#" @click.prevent="showSocial = !showSocial">
        {{ song.user.name }}
        <unicon name="share-alt"></unicon>
      </a>
    </h3>

    <div class="progress" @click="seekTimer($event)" ref="progressbar">
      <p class="progress__title">{{ getTruncateTitle() }}</p>
      <div class="progress__overlay" id="overlay" :style="'clip-path: inset(0px  0px 0px ' + progress + '%);'">
        <div class="progress__overlay-middle">
          <p class="progress__title">{{ getTruncateTitle() }}</p>
        </div>
      </div>
      <div class="progress__bar" id="bar" :style="'width: ' + progress + '%;'"></div>
    </div>

    <div class="timer">
      <div>{{ timer }}</div>
      <div>-</div>
      <div>{{ duration }}</div>
    </div>

  </section>
</template>

<script>
import {onMounted, ref} from "vue";

export default {
  name: 'PlayerTimer',
  props: {
    song: {
      type: Object,
      default: null
    },
    timer: {
      type: String,
      default: '00:00'
    },
    duration: {
      type: String,
      default: '00:00'
    },
    progress: {
      type: Number,
      default: 0
    },
  },
  setup(props, { emit }) {
    /**
     * Data ------
     */
    const showSocial = ref(false)
    const progressbar = ref(null)

    /**
     * Method ------
     */
    function seekTimer(e) {
      let seekEventAction = {
        clickX: e.offsetX,
        progressbarWidth: progressbar?.value?.clientWidth
      }
      emit('emit-seekTimer', seekEventAction)
    }

    function getTruncateTitle() {
      let title = props.song.title ? props.song.title : 'Loading'
      let n = 22
      title = (title.length > n) ? title.substr(0, n-1) + '...' : title;
      //console.log(title)
      return title
    }

    onMounted(() => {
      //emit('emit-progressbarWidth', progressbar.value.clientWidth)
      //console.log(progressbar.value.clientWidth)
    });

    return {
      /* Data ------ */
      showSocial,
      progressbar,
      /* Method ------ */
      seekTimer,
      getTruncateTitle
    }
  }
}
</script>

<style lang="scss">
  .pm_timer{
    text-align: center;
    padding: var(--space) 0;
    margin: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    a {
      text-decoration: none;
    }
    h2 {
      color: var(--color);
      font-size: 1.4em;
      margin: 0;
      font-family: Quicksand-Bold, sans-serif;
    }
    .author {
      font-size: 1.125em;
      color: var(--color);
      font-weight: 400;
      font-family: Quicksand-Italic, sans-serif;
      margin: 0;
      display: flex;
      a {
        padding: calc(var(--space) / 2);
        display:flex;
        align-items: center;
        .unicon{
          width: var(--sizeicon-mini);
          padding: 0;
          margin-left: 5px;
          svg{
            fill: var(--color);
          }
        }
        &:hover{
          svg{
            fill: var(--contrast);
          }
        }
      }
    }
    .author__link{
      position: absolute;
      top: -20px;
      //left: 0;
      text-align: center;
      padding: 0px 30px;
      font-family: Quicksand-Medium, sans-serif;
      a {
        padding: calc(var(--space) / 2) var(--space);
        display: inline-flex;
        .unicon{
          width: var(--sizeicon-small);
        }
      }
    }

    .timer {
      color: var(--color);
      padding: calc(var(--space) / 2) 0;
      font-size: 12px;
      font-family: Quicksand-Medium, sans-serif;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center !important;
      width: 100%;
      div:first-child,
      div:last-child {
        margin: 0;
      }
      div:nth-child(2){
        margin: 0 10px;
      }
    }
    .progress{
      //background:rgba(255,255,255,0.1);
      position:relative;
      display: flex;
      cursor: pointer;
      overflow: hidden;
      align-items: center;
      justify-content: center !important;
      vertical-align: middle;
      &__overlay {
        background-color: var(--background);
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        color: #444;
        display: flex;
        justify-content: center;
        clip-path: inset(0 50vw 0 0);
        pointer-events: none;
        transition: clip-path 200ms linear;
        &-middle {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
      }

      &__title {
        color: var(--color-light);
        font-weight: 700;
        line-height: 1.6;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.5rem;
        width: 100%;
        margin: 0 0 -3px;
        padding: 5px 0 4px 0;
        letter-spacing: -1px;
        @media (min-width: 350px) {
          font-size: 1.7rem;
        }
        @media (min-width: 599px) {
          font-size: 2.1rem;
        }
      }
      &__overlay .progress__title {
        color: var(--color-dark-inversed);
      }

      &__bar{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        border-right: 2px solid var(--contrast);
        //z-index: 9;
        background: transparent;
        //transition: width 0.2s cubic-bezier(1, 0, 0.255, 1);
        transition: width 100ms linear;
      }
    }
  }
</style>
