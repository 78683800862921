<template>
  <section class="pm_bottom-sheet" ref="bottomSheet">
    <div class="pm_bottom-sheet__dragbar"></div>
    <section class="pm_bottom-sheet__wrapper">
      <div class="pm_scenes">
        <h3><unicon name="setting"></unicon> My settings</h3>
        <div class="pm_scenes__shortcut">
          <a href="#" class="small">
            <unicon name="volume"></unicon>
          </a>
          <a href="#" class="small">
            <unicon name="layers-slash"></unicon>
          </a>
          <a href="#" class="small">
            <unicon name="heart"></unicon>
          </a>
          <a href="#" class="small">
            <unicon name="robot"></unicon>
          </a>
        </div>

        <h3><unicon name="setting"></unicon> Choose Playlist style</h3>
        <a href="#" v-for="song in playlists" :key="song.title" class="pm_playlist">
          <figure>
            <img
                alt="Vue logo"
                class="img-cover"
                :src="'playlist-offline/cover/' + song.cover"
            />
            <figcaption>{{ song.title }}</figcaption>
          </figure>
        </a>
      </div>
    </section>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { onMounted, ref } from "vue";

export default {
  name: 'playerBottomSheet',
  props: {
    playlists: {
      type: Array,
      default: null
    }
  },
  setup() {

    const isOpen = ref(false)
    const bottomSheet = ref(null)
    const openBottomY = ref(-160)
    const closeBottomY = ref(420)
    const threshold = ref(10)

    /**
     * Mounted, Watcher ------
     */
    onMounted(() => {
      gsap.registerPlugin(Draggable);
      gsap.set(".pm_bottom-sheet", {y: closeBottomY.value});
      Draggable.create(".pm_bottom-sheet", {
        type: "y",
        edgeResistance: 0.5,
        dragResistance: 0.01,
        bounds: { minY:openBottomY.value, maxY: closeBottomY.value },
        inertia: true,
        liveSnap: true,
        onMove: function () {
          //console.log(e);
          //console.log(this.y);
          //console.log(this.target);
          //console.log(playlistRef.value);
        },
        onDragEnd: function () {
          if (
              this.y <= (closeBottomY.value - threshold.value)
              && this.y >= (openBottomY.value + threshold.value)
              && isOpen.value === false
          ) {
            gsap.to(bottomSheet?.value, { y: openBottomY.value, duration: 0.35 });
            bottomSheet?.value.classList.add("open");
            isOpen.value = true;
          }else{
            gsap.to(bottomSheet?.value, { y: closeBottomY.value, duration: 0.35 });
            bottomSheet?.value.classList.remove("open");
            isOpen.value = false;
          }
        }
      });
    });

    return {
      bottomSheet,
      isOpen
    }
  }
}
</script>

<style lang="scss">
  .pm_bottom-sheet {
    width: 95vw;
    max-width: 450px;
    min-height: 300px;
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform: translateX(-50%);
    //backdrop-filter: blur(6px) saturate(180%);
    display: flex;
    flex-direction: column;
    &__dragbar{
      width: 80px;
      height: 5px;
      background: var(--contrast);
      border-radius: 14px;
      margin: calc(var(--gap) * 2) auto;
    }
    &__wrapper{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: var(--space);
      border-radius: 18px;
      background-color: var(--background-dark);
      box-shadow: 0 1px 8px var(--shadow);
    }
  }
  .pm_bottom-sheet.open {
    &__wrapper{
      box-shadow: 0 1px 20px var(--shadow);
    }
  }
  .pm_scenes {
    width: 100%;
    height: auto;
    position: relative;
    padding: var(--gap);
    text-align: left;
    h3 {
      color: var(--color);
      font-size: 1.2em;
      margin: var(--space) 0;
      font-family: Quicksand-Medium, sans-serif;
    }
    &__shortcut {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: calc(var(--gap) * 2);
      a{
        display: flex;
        margin: 0 var(--space);
        padding: var(--space);
        background: var(--color-transparent);
        border-radius: 50px;
        .unicon{
          width: var(--sizeicon);
        }
      }
    }
    .pm_playlist{
      width: 110px;
      height: 110px;
      display: inline-flex;
      margin: 10px;
      figure {
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        -webkit-filter: grayscale(0.5);
        filter: grayscale(0.5);
        border-radius: var(--border-radius);
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        &:hover{
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
        }
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, #252525 10%, transparent 100%);
          top: 0;
          left: 0;
          opacity: 0.8;
          z-index: 1;
        }
        figcaption {
          color: #fff;
          font: 10px sans-serif;
          text-transform: uppercase;
          font-weight: bold;
          padding: 3px;
          text-align: center;
          display: flex;
          position: absolute;
          bottom:5px;
          width: 100%;
          justify-content: center;
          z-index: 3;
        }
        .unicon {
          margin-top: -10px;
          svg {
            width: 30px;
          }
        }
      }
    }
  }
</style>
