const songs = [
  {
    id: "00001",
    title: "I need a nap",
    artist: "Bubblebeat",
    cover: "cover-forest.jpg",
    src: "audios/Bubblebeat_nocopyrightmusic-i_need_a_nap-Soundcloud.mp3",
    link: "https://playme.mx"
  },
  {
    id: "00002",
    title: "Fashion Hip-Hop",
    artist: "Alexey Anisimov",
    cover: "cover-sea.jpg",
    src: "audios/Alexey_Anisimov-Fashion_Hip-Hop-Jamedo.mp3",
    link: "https://playme.mx"
  },
  {
    id: "00003",
    title: "Cinematic chillhop main",
    artist: "Good B Music",
    cover: "cover-rooftop.jpg",
    src: "audios/GoodBMusic-Cinematic_chillhop_main-Pixabay.mp3",
    link: "https://playme.mx"
  },
  {
    id: "00004",
    title: "Cali",
    artist: "Wataboi",
    cover: "cover-sun.jpg",
    src: "audios/Wataboi-Cali-Pixabay.mp3",
    link: "https://playme.mx"
  },
  {
    id: "00005",
    title: "Funky trap sax",
    artist: "John Sib",
    cover: "cover-jazz.jpg",
    src: "audios/John_Sib-funky_trap_sax-Pixabay.mp3",
    link: "https://playme.mx"
  },
  {
    id: "00006",
    title: "Bay View - Paklite x Devon Rea",
    artist: "John Sib",
    cover: "cover-jazz.jpg",
    src: "audios/John_Sib-funky_trap_sax-Pixabay.mp3",
    link: "https://playme.mx"
  },
];

export default songs;
