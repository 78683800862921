import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Privacy from './views/Privacy.vue'

// lazy-loaded when the route is visited. generates a separate chunk (about.[hash].js)
function loadView(view) {
    return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/ux',
    name: 'Ux',
    component: loadView('Ux')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
