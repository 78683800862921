import ApiAudiusService from "../../services/ApiAudiusService";

const state = () => ({
  isReady: true,
  isPlaying: false,
  currentSongIndex: 0,
  currentPlaylist: [],
})

// getters
const getters = {
  isReady: state => state.isReady,
  isPlaying: state => state.isPlaying,
  currentSongIndex: state => state.currentSongIndex,
  currentPlaylist: state => state.currentPlaylist
}

// mutations
const mutations = {
  SET_READY(state, payload) {
    state.isReady = payload;
  },
  SET_PLAY_PAUSE(state, payload) {
    state.isPlaying = payload;
  },
  SET_CURRENT_SONG_INDEX(state, payload) {
    state.currentSongIndex = payload;
  },
  SET_CURRENT_PLAYLIST(state, payload) {
    state.currentPlaylist = payload;
  }
}

// actions
const actions = {
  toggleReady({ commit }, payload) {
    commit('SET_READY', payload);
  },
  togglePlayPause({ commit }, payload) {
    commit('SET_PLAY_PAUSE', payload);
  },
  changeSongIndex({ commit }, payload) {
    if (payload !== state.currentSongIndex) {
      commit('SET_CURRENT_SONG_INDEX', payload);
    }
  },
  nextSong({ commit }, payload) {
    if (payload.current >= payload.total - 1){
      // commit('SET_CURRENT_SONG_INDEX', 0);
      commit('SET_CURRENT_SONG_INDEX', payload.total - 1);
    }else {
      commit('SET_CURRENT_SONG_INDEX', payload.current + 1);
    }
  },
  prevSong({ commit }, payload) {
    if (payload <= 0){
      commit('SET_CURRENT_SONG_INDEX', 0);
    }else {
      commit('SET_CURRENT_SONG_INDEX', payload - 1);
    }
  },
  async initCurrentPlaylist({ commit }, payload) {
    let res = await ApiAudiusService.getAllTracksByPlaylistId(payload)
    commit('SET_CURRENT_PLAYLIST', res);
  },
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
