<template>
  <div class="about container">

    <h3>
      <unicon name="comment-info"></unicon> About
    </h3>

    <p>
      <unicon name="no-entry"></unicon> Work in progress 🚧..
    </p>

    <p>
      <unicon name="robot"></unicon>
      Hand-made random playlist songs for concentration
    </p>

    <p>
      <unicon name="share-alt"></unicon>
      Decentralized music based on blockchain <a href="https://audius.co" target="_blank" rel="noopener">Audius</a>..
    </p>

    <p>
      <unicon name="brain"></unicon>
      No distraction, Focus, No Ads (forever)..
    </p>

    <p>
      <unicon name="rocket"></unicon> urls:
      <a href="https://playme.mx" target="_blank" rel="noopener">playme.mx</a> |
      <a href="https://lofi.mx" target="_blank" rel="noopener">lofi.mx</a>
    </p>
    <p>
      <unicon name="favorite"></unicon>
      Premium features: soon..
    </p>

    <p>
      <unicon name="github-alt"></unicon>
      Release open-source: soon..
    </p>

    <p>
      <unicon name="shield-check"></unicon>
      Privacy policy: <router-link to="/privacy">soon..</router-link>
    </p>

    <p>
      <unicon name="palette"></unicon>
      Ux components : <router-link to="/ux">soon..</router-link>
    </p>

    <p>
      <unicon name="bug"></unicon>
      A bug ? Contribute with <a href="mailto:hello/at/playme/dot/mx" rel="noopener">me</a>.
    </p>

    <p>
      &lt;&sol;💻&gt; with ❤ by
      <a href="https://in2.app" title="In2 App" target="_blank" rel="noopener">@in2.app</a>.
      Have Fun 🎶
    </p>

  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped lang="scss">
  .about{
    text-align: left;
  }
</style>
